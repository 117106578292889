import "../css/swagger-ecl.css";
import "../css/swagger-ui.css";
import React, { useEffect, useRef } from "react";
import { Layout } from "components";

const IndexPage = ({ location }) => {
  const swaggerRef = useRef(null);

  useEffect(() => {
    window.SwaggerUI({
      domNode: swaggerRef.current,
      url: `${window.location.origin}/constellation-api.json`,
    });
  }, []);

  return (
    <Layout clearNav={false} location={location}>
      <div ref={swaggerRef} id="swaggerWrapper" />
    </Layout>
  );
};

export default IndexPage;
